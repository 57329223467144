// import { useCallback, useMemo, useState, useEffect } from 'react';
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/nl';

import useApi from '../useApi';

const FALLBACK_CANVAS_URL = process.env.REACT_APP_CANVAS_URL;

const MAX_GROUPS_IN_EVENT = 2;
const CACHE_LIFE = 2 * 60 * 1000; // 2 minutes


function useCalendarApi (flexx_id = false) {
    const path = flexx_id ? `/students/${flexx_id}/calendar` : `/user/calendar`;

    const { get, value, loading, fetched, error } = useApi('agenda', path, { initialValue: [], cachePolicy: 'cache-first', cacheLife: CACHE_LIFE });

    const events = useEventsMemo(value);

    const exposedGet = useCallback((start = null, end = null) => {
        const paramString = start && end ? `${start}/${end}` : start;
        get(paramString);
    }, [get]);

    return {
        get: exposedGet,
        events,
        loading,
        fetched,
        error,
    };
}

export default useCalendarApi;

function useEventsMemo (_events = []) {

//     forced duration method for testing different duration events
//     const [forcedDuration, setForcedDuration] = useState(0);
//     const setForcedDurationCallback = useCallback((dur) => {
//         dur = parseInt(dur, 10);
//         if (!isNaN(dur)) {
//             setForcedDuration(Math.abs(dur));
//         } else {
//             setForcedDuration(0);
//         }
//     }, []);
//
//     useEffect(() => {
//         window.caldur = setForcedDurationCallback;
//         return () => {
//             window.caldur = undefined;
//         }
//     }, [setForcedDurationCallback]);

    return useMemo(() => (
        _events.map((_event) => {
            const {
                title,
                type,
                appointment: {
                    start,
                    end,
                },
                location: {
                    building,
                    room,
                },
                group_courses,
                raw: {
                    raw_event: {
                        id: rawId
                    }
                } = { raw_event: { } },
                teachers: [ { full_name: fallbackTeacher } = {} ] = [],
                ...event
            } = _event;

            // limit group courses to max
            const groupCourses = group_courses.slice(0, MAX_GROUPS_IN_EVENT);

            // get teacher and canvas url from first group_course
            const defaultGroupCourse = groupCourses[0] || {};
            const {
                teachers: [ { full_name: _teacher } = {} ] = [],
                scheduled_course: {
                    canvas_url: canvasUrl,
                } = {},
            } = defaultGroupCourse;

            const teacher = _teacher || fallbackTeacher;

            // dev forced duration stuff
            // if duration is forced, set end time to start time + forced duration
            // let end = _end;
            // if (forcedDuration) {
            //     end = new Date(new Date(start).getTime() + forcedDuration * 60 * 1000);
            // }

            const sm = moment(start);
            const em = moment(end);
            const idDateTimeString = sm.format('YYMMDD') + '_' + sm.format('kkmm') + '-' + em.format('kkmm');

            return {
                id: rawId || `${title.substr(0, 12).trim()}_${idDateTimeString}`,
                type,
                title,
                start: new Date(start),
                end: new Date(end),
                groupCourses,
                teacher,
                building: building !== 'Onbekend' ? building : null,
                room: room !== 'Onbekend' ? room : null,
                ...(type === 'lesson' && {
                    canvasUrl: canvasUrl || FALLBACK_CANVAS_URL,
                }),
                ...event,
            };
        })
    ), [_events]);
    // ), [_events, forcedDuration]);
}
