import { useMemo, useState, useCallback, useReducer, useEffect } from 'react';
import { useDebouncedCallback } from "use-debounce";

import useApi from '../useApi';

import STATUS from '../../data/rating_statusses.json';
import { cleanupCourseName, getResultBasedStatus } from '../../utils';

import useRatingsContext from "../../context/RatingsContext";

const INIT_VALUE = {
    scheduled_course: { course: { }, block: { } }
};

const DEFAULT_SESSIONS = parseInt(process.env.REACT_APP_FLEXX_DEFAULT_SESSIONS, 10);
const DEBOUNCE_THRESHOLD = 1000;

function useRatingGroupCourseApi (groupCourseId) {

    /* * * * * * * * * * * * * * * * * * * * * * * * * *
     * GET FULL COURSE MODEL AND ALL STUDENTS IN GROUP *
    ** * * * * * * * * * * * * * * * * * * * * * * * * */
    const { get, loading, value, fetched } = useApi('cursus beoordelingen', `/ratings/${groupCourseId}`, { initialValue: INIT_VALUE });

    const {
        group_code: groupCode,
        sessions: _sessions,
        period,
        block_year: schoolYear,
        rating: _ratings,
        course_criteria: _criteria = []
    } = value;

    /* * * * * * * * * * * *
     * "ACTUAL" SESSIONS  *
    ** * * * * * * * * * * */
    // 'live sessions' OR ELSE 'admin portal sessions' OR ELSE from .env
    const sessions = _sessions ?? DEFAULT_SESSIONS;

    /* * * * * * * * * * * * * *
     * REMAP AND SORT CRITERIA *
    ** * * * * * * * * * * * * */
    const [memoizedCriteria, criteria] = useMemo(() => {
        const criteria = _criteria
            .reduce((acc, criterium) => {
                const {
                    group_order: groupOrder,
                    code,
                    name,
                    type_id,
                    description,
                    needed_for_closing: neededForClosing,
                    counts_towards_progress: countsTowardsProgress
                } = criterium;

                const _criterium = {
                    neededForClosing,
                    countsTowardsProgress,
                    typeId: type_id,
                    code,
                    name,
                    description,
                };

                if (acc[groupOrder]) {
                    acc[groupOrder].push(_criterium);
                    acc[groupOrder].sort(({ order: a }, { order: b }) => {
                        return a < b ? -1 : b < a ? 1 : 0;
                    });
                } else {
                    acc[groupOrder] = [_criterium];
                }

                return acc;
            }, [])
            .filter((criteriaGroup) => criteriaGroup && criteriaGroup.length);

        return [true, criteria];
    }, [_criteria]);

    /* * * * * * * * * * * * * * * *
     * REDUCER FOR STUDENT RATINGS *
    ** * * * * * * * * * * * * * * */
    const [students, dispatchStudents] = useReducer(studentScoreReducer, []);

    /* * * * * * * * * * * *
     * FETCH AND DISPATCH  *
    ** * * * * * * * * * * */
    const fetchWhen = !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [fetchWhen, get]);

    const dispatchWhen = fetched && !loading && memoizedCriteria;
    useEffect(() => {
        if (dispatchWhen) {
            dispatchStudents({
                type: 'INIT',
                ratings: _ratings,
                criteria,
            });
        }
    }, [dispatchWhen, _ratings?.length, criteria?.length, _ratings, criteria]);

    /* * * * * * * * * *
     * EXPOSED METHODS *
    ** * * * * * * * * */
    // update rating
    const remapScorePayload = useCallback(({ ratingIds, score }) => ({
        rating_ids: ratingIds,
        score,
    }), []);
    const remapScoreResponse = useCallback(({ rating_ids, score }, { typeId }) => ({
        ratingIds: rating_ids,
        score,
        typeId,
    }), []);
    const updateScore = usePostRatingsPropertyApi('criterium beoordeling updaten', dispatchStudents, 'UPDATE_SCORE', remapScorePayload, remapScoreResponse);
    const exposedUpdateScore = useCallback((ratingIds, typeId, score) => {
        updateScore(
            `ratings/criterium/${typeId}/`,
            { ratingIds, typeId, score },
        );
    }, [updateScore]);

    // update status
    const remapStatusPayload = useCallback(({ status }) => ({ status }), []);
    const updateStatus = usePostRatingsPropertyApi('beoordelingsstatus opslaan', dispatchStudents, 'UPDATE_STATUS', remapStatusPayload);
    const exposedUpdateStatus = useCallback((ratingId, status) => {
        updateStatus(
            `ratings/${ratingId}/status/`,
            { status, ratingId }
        );
    }, [updateStatus]);

    // update result
    const remapResultPayload = useCallback(({ result }) => ({ result }), []);
    const saveUpdatedResult = usePostRatingsPropertyApi('beoordelingsstatus opslaan', dispatchStudents, 'UPDATE_RESULT', remapResultPayload);
    const debouncedResultUpdate = useDebouncedCallback((ratingId, result, status) => {
        saveUpdatedResult(
            `ratings/${ratingId}/result/`,
            { ratingId, result }
        );

        updateStatus(
            `ratings/${ratingId}/status/`,
            { status, ratingId }
        );
    }, DEBOUNCE_THRESHOLD);
    const updateLocalResult = usePostRatingsPropertyApi('beoordelingsstatus opslaan', dispatchStudents, 'UPDATE_RESULT', remapResultPayload, null, true);
    const exposedUpdateResult = useCallback((ratingId, resultType, result) => {
        result = result.replaceAll(',', '.');

        updateLocalResult(
            `ratings/${ratingId}/result/`,
            { ratingId, result }
        );

        const status = getResultBasedStatus(result, resultType);
        debouncedResultUpdate(ratingId, result, status);
    }, [updateLocalResult, debouncedResultUpdate]);

    // update feedback
    const remapFeedbackPayload = useCallback(({ feedback }) => ({ feedback }), []);
    const updateFeedback = usePostRatingsPropertyApi('feedback updaten', dispatchStudents, 'UPDATE_FEEDBACK', remapFeedbackPayload);
    const exposedUpdateFeedback = useCallback((ratingId, feedback) => {
        updateFeedback(
            `ratings/${ratingId}/feedback/`,
            { feedback, ratingId }
        );
    }, [updateFeedback]);

    const exposedUpdateLocalFeedback = useCallback((ratingId, feedback) => {
        dispatchStudents({ type: 'UPDATE_LOCAL_FEEDBACK', ratingId, feedback });
    }, []);

    // update publishing state
    const remapPublishPayload = useCallback(({ ratingIds }) => ({
        rating_ids: ratingIds,
        published: true,
    }), []);
    const remapPublishResponse = useCallback(({ rating_ids, published }) => ({
        ratingIds: rating_ids,
        published,
    }), []);
    const publishResults = usePostRatingsPropertyApi('publicatie status opslaan', dispatchStudents, 'PUBLISH', remapPublishPayload, remapPublishResponse);
    const exposedPublishResults = useCallback((ratingIds) => {
        publishResults('ratings/publish/', { ratingIds });
    }, [publishResults]);

    // update selection
    const selectStudent = useCallback((studentId, selected = true) => {
        dispatchStudents({
            type: 'UPDATE_SELECTION',
            studentId,
            selected
        });
    }, []);

    const selectAllStudents = useCallback((selected = true) => {
        dispatchStudents({
            type: 'UPDATE_SELECTION_ALL',
            selected
        });
    }, []);

    // update current student (responsive panel)
    const setCurrentStudent = useCallback((studentId) => {
        dispatchStudents({
            type: 'SET_CURRENT_STUDENT',
            studentId
        });
    }, [])

    const unsetCurrentStudent = useCallback(() => {
        dispatchStudents({
            type: 'UNSET_CURRENT_STUDENT'
        });
    }, []);

    // sort list
    const [sortDirection, setSortDirection] = useState('ASC');
    const [sortProperty, setSortProperty] = useState('last_name')

    const sortStudents = useCallback((direction = 'ASC', property = 'last_name') => {
        setSortDirection(direction);
        setSortProperty(property);
        dispatchStudents({ type: 'SORT', direction, property });
    }, []);

    // filter list
    const filterStudents = useCallback((search) => {
        dispatchStudents({ type: 'FILTER', search });
    }, []);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const { cleanName: courseName } = cleanupCourseName(value);

    return {
        courseName,
        groupCode,
        block: {
            period,
            school_year: (schoolYear) ? `${schoolYear}/${parseInt(schoolYear) + 1}` : schoolYear
        },
        sessions,
        criteria,
        students,
        sortStudents,
        sortDirection,
        sortProperty,
        filterStudents,

        updateResult: exposedUpdateResult,
        updateScore: exposedUpdateScore,
        updateStatus: exposedUpdateStatus,
        updateFeedback: exposedUpdateFeedback,
        publish: exposedPublishResults,

        updateLocalFeedback: exposedUpdateLocalFeedback,

        selectStudent,
        selectAllStudents,

        setCurrentStudent,
        unsetCurrentStudent,

        loading,
        fetched
    };
}

function usePostRatingsPropertyApi (identifier, dispatcher, dispatchType, remapPayload, remapResponse, onlyLocal = false) {
    const { post } = useApi(identifier, '');
    const { setIsPublished } = useRatingsContext();

    const dispatch = useCallback((payload) => {
        dispatcher({ type: dispatchType, ...payload });
    }, [dispatcher, dispatchType]);

    const exposedPost = useCallback(async (path = '', payload = {}) => {
        // first dispatch reducer for local state
        // and store payload in (temp) state for use later
        dispatch(payload);

        const postPayload = typeof remapPayload === 'function' ?
            remapPayload(payload) :
            payload;

        // do call
        if (!onlyLocal) {
            post(path, postPayload).then((response) => {
                // check if response is actually an object
                const isObject = response === Object(response);
                if (response?.data?.published) {
                    setIsPublished(true);
                }

                if (isObject) {
                    const { data = {} } = response;

                    const dispatchData = typeof remapResponse === 'function' ?
                        remapResponse(data, payload) :
                        data;

                    dispatch(dispatchData);
                }
            });
        }
    }, [dispatch, remapPayload, onlyLocal, post, setIsPublished, remapResponse]);

    return exposedPost;
}

function studentScoreReducer (state, action) {
    const { type } = action;

    switch (type) {
        case 'INIT': {
            const { ratings, criteria } = action;
            const remappedStudents = ratings.map((rating = {}) => {
                const {
                    id: ratingId,
                    student_number: flexx_id,
                    student_first_name: first_name,
                    student_last_name: last_name,
                    student_full_name: full_name,
                    student_group: base_group,
                    feedback,
                    result,
                    result_type,
                    published,
                    attendance,
                    criteria: studentCriteriaRatings
                } = rating;

                let { status } = rating;
                if (!isValidStatus(status)) {
                    status = STATUS.BLANK;
                }

                criteria.flat().forEach(({ code }) => {
                    if (!studentCriteriaRatings.hasOwnProperty(code)) {
                        studentCriteriaRatings[code] = 0;
                    }
                });

                const criteriaRatings = criteria.flat().map(({ typeId, code, countsTowardsProgress, neededForClosing }) => {
                        const score = studentCriteriaRatings[code];
                        return {
                            code,
                            typeId,
                            countsTowardsProgress,
                            neededForClosing,
                            score
                        }
                    });

                const _student = {
                    flexx_id,
                    first_name,
                    last_name,
                    full_name,
                    base_group,
                    ratingId,
                    ratings: {
                        id: ratingId,
                        criteriaRatings,
                        feedback,
                        localFeedback: feedback,
                        status,
                        published,
                        attendance,
                        result,
                        resultType: result_type
                    },
                    current: false, // <- state for showing student in panel in responsive designs
                    selected: false, // <- state for multiselect students
                    visible: true, // <- state for search/filter
                };

                return addFlagsToStudent(_student);
            }).sort(({ last_name: a }, { last_name: b }) => a < b ? -1 : b < a ? 1 : 0);

            return remappedStudents;
        }
        case 'SORT': {
            const { direction = 'ASC', property = 'last_name' } = action;

            state.sort(({ [property]: a }, { [property]: b }) => {
                if (direction === 'ASC') {
                    return a < b ? -1 : b < a ? 1 : 0;
                }
                return a < b ? 1 : b < a ? -1 : 0;
            });

            return [ ...state ];
        }
        case 'FILTER': {
            const { search = '' } = action;

            if (!search.length) {
                return state.map((student) => ({
                    ...student,
                    visible: true
                }));
            }

            return state.map(({ full_name, flexx_id, ...student }) => ({
                ...student,
                full_name,
                flexx_id,
                visible: !search.length || full_name.toLowerCase().includes(search.toLowerCase()) || flexx_id.includes(search)
            }))
        }
        case 'UPDATE_RESULT': {
            const { result, ratingId } = action;

            return state.map((student) => {
                const { ratings: { id: _ratingId } = {} } = student;

                if (ratingId === _ratingId) {
                    const _student = {
                        ...student,
                        ratings: {
                            ...student.ratings,
                            result
                        },
                    };

                    return addFlagsToStudent(_student);
                }

                return student;
            });
        }
        case 'UPDATE_SCORE': {
            const { typeId, score, ratingIds } = action;

            return state.map((student) => {
                const { ratings: { criteriaRatings, id: _ratingId } = {} } = student;

                if (ratingIds.includes(_ratingId)) {
                    const newCriteriaRatings = criteriaRatings.map(({ typeId: _ctId, ...criteriumRating }) => ({
                        ...criteriumRating,
                        typeId: _ctId,
                        ...(typeId === _ctId && { score })
                    }));

                    const _student = {
                        ...student,
                        ratings: {
                            ...student.ratings,
                            criteriaRatings: newCriteriaRatings,
                        },
                    };

                    return addFlagsToStudent(_student);
                }

                return student;
            });
        }
        case 'UPDATE_STATUS': {
            const { status, ratingId } = action;

            // blanco state not allowed from frontend
            if (![STATUS.COMPLETED, STATUS.NOT_COMPLETED, STATUS.CLOSED].includes(status)) {
                return state;
            }

            return state.map((student) => {
                const { ratings: { id: _ratingId } = {}, closable, completable } = student;

                // check if new state is valid for student
                const validAction = (closable && status === STATUS.CLOSED) ||
                    (completable && [STATUS.COMPLETED, STATUS.NOT_COMPLETED].includes(status))

                if (validAction && ratingId === _ratingId) {
                    const _student = {
                        ...student,
                        ratings: {
                            ...student.ratings,
                            status,
                        },
                    };

                    return addFlagsToStudent(_student);
                }

                return student;
            });
        }
        case 'PUBLISH': {
            const { ratingIds, published } = action;

            return state.map((student) => {
                const { ratings: { id: _ratingId } = {}, publishable } = student;

                const validAction = (published && publishable) || !published;

                if (validAction && ratingIds.includes(_ratingId)) {
                    const _student = {
                        ...student,
                        ratings: {
                            ...student.ratings,
                            published,
                        },
                    };

                    return addFlagsToStudent(_student);
                }

                return student;
            });
        }
        case 'UPDATE_FEEDBACK': {
            const { feedback, ratingId } = action;

            return state.map((student) => {
                const { ratings: { id: _ratingId } = {} } = student;

                if (ratingId === _ratingId) {
                    const _student = {
                        ...student,
                        ratings: {
                            ...student.ratings,
                            feedback,
                            localFeedback: feedback,
                        },
                    };

                    return addFlagsToStudent(_student);
                }

                return student;
            });
        }
        case 'UPDATE_LOCAL_FEEDBACK': {
            const { feedback, ratingId } = action;

            return state.map((student) => {
                const { ratings: { id: _ratingId } = {} } = student;

                if (ratingId === _ratingId) {
                    const _student = {
                        ...student,
                        ratings: {
                            ...student.ratings,
                            localFeedback: feedback,
                        },
                    };

                    return addFlagsToStudent(_student);
                }

                return student;
            });
        }
        case 'UPDATE_SELECTION': {
            const { selected, studentId } = action;

            return state.map((student) => {
                const { flexx_id } = student;

                if (studentId === flexx_id) {
                    return {
                        ...student,
                        selected: selected || false,
                    };
                }

                return student;
            })
        }
        case 'UPDATE_SELECTION_ALL': {
            const { selected } = action;

            return state.map((student) => {
                return {
                    ...student,
                    selected: selected || false
                };
            });
        }
        case 'SET_CURRENT_STUDENT': {
            const { studentId } = action;

            return state.map((student) => {
                const { flexx_id } = student;
                return {
                    ...student,
                    current: flexx_id === studentId,
                };
            });
        }
        case 'UNSET_CURRENT_STUDENT': {
            return state.map((student) => ({
                ...student,
                current: false,
            }));
        }
        default: {
            return state;
        }
    }
}

function addFlagsToStudent (student) {
    const {
        ratings: {
            feedback,
            localFeedback,
            published,
            status,
            criteriaRatings,
        },
    } = student;

    const hasUnsavedFeedback = feedback !== localFeedback;

    const ratingsNeededForClosing = criteriaRatings.filter(({ neededForClosing }) => neededForClosing);
    const ratingsNeededForCompletion = criteriaRatings.filter(({ countsTowardsProgress }) => countsTowardsProgress);

    const completable = ratingsNeededForCompletion.every(({ score }) => score > 0);
    const closable = ratingsNeededForClosing.every(({ score }) => score > 0);
    const publishable = !published && [STATUS.COMPLETED, STATUS.NOT_COMPLETED, STATUS.CLOSED].includes(status);

    const allowEmptyFeedback = true;
    const constrainRatings = [STATUS.COMPLETED, STATUS.NOT_COMPLETED].includes(status);
    const constrainClosingRatings = status === STATUS.CLOSED;

    return {
        ...student,
        completable,
        closable,
        publishable,
        hasUnsavedFeedback,
        allowEmptyFeedback,
        constrainRatings,
        constrainClosingRatings,
    };
}

function isValidStatus (status) {
    return Object.keys(STATUS).map((key) => STATUS[key]).includes(status);
}

export default useRatingGroupCourseApi;
